const endpoints = {
  auth: {
    login: '/auth/login',
    register: '/auth/register',
    verifyUser: '/auth/verifyUser',
    verifyToken: '/auth/verifyToken',
    renewToken: '/auth/renewToken',
    resetPasswordEmail: '/auth/resetPasswordEmail',
    resetPassword: '/auth/resetPassword',
  },
  papers: {
    getPapersBackoffice: '/app/papers/getPapersBackoffice',
    getPapers: '/app/papers/getPapers',
    getPapersEvents: '/app/papers/getPapersEvents',
    getTopPapers: '/app/papers/getTopPapers',
    getUserFavorites: '/app/papers/getUserFavorites',
    getFavPapers: '/app/papers/getFavPapers',
    editPaper: '/app/papers/editPaper',
    postPaper: '/app/papers/postPaper',
    deletePaper: '/app/papers/deletePaper',
    postUserFavorite: '/app/papers/postUserFavorite',
    deleteUserFavorite: '/app/papers/deleteUserFavorite',
    postUserView: '/app/papers/postUserView',
    postUserShare: '/app/papers/postUserShare',
  },
  categories: {
    getCategoriesBackoffice: '/app/categories/getCategoriesBackoffice',
    getCategories: '/app/categories/getCategories',
    postCategory: '/app/categories/postCategory',
    editCategory: '/app/categories/editCategory',
    deleteCategory: '/app/categories/deleteCategory',
  },
  types: {
    getTypes: '/app/types/getTypes',
    postType: '/app/types/postType',
    editType: '/app/types/editType',
  },
  events: {
    getEvents: '/app/events/getEvents',
    postEvent: '/app/events/postEvent',
    editEvent: '/app/events/editEvent',
  },
  podcasts: {
    getPodcasts: '/app/podcasts/getPodcasts',
    postPodcast: '/app/podcasts/postPodcast',
    editPodcast: '/app/podcasts/editPodcast',
    deletePodcast: '/app/podcasts/deletePodcast'
  },
  info: {
    appInfo: '/app/info/appInfo',
    getAppCategories: '/app/info/getAppCategories',
    postVersion: '/app/info/postVersion',
    infoDashboard: '/app/info/infoDashboard',
  },
  users: {
    getUsers: '/app/users/getUsers'
  },
}

export default endpoints;