import {
  Row,
  Col,
  Card,
  Modal,
  Table,
  Button,
  Input,
  Form,
  Spin,
  notification,
  Upload
} from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  LoadingOutlined,
  UploadOutlined,
  ReloadOutlined
} from '@ant-design/icons';
import { useEffect, useState } from "react";
import axios from 'axios';
import "./Types.css";
import config from "../../utils/config";
import endpoints from "../../utils/endpoints";
import Create from "../../components/Types/Create";
import Update from "../../components/Types/Update";

const SpinIcon = (
  <LoadingOutlined
    style={{
      fontSize: 16,
      marginRight: '10px',
      color: '#009195'
    }}
    spin
  />
);

const columns = [
  {
    title: "#",
    dataIndex: "key",
    key: "key",
    width: "5%"
  },
  {
    title: "NAME",
    dataIndex: "name",
    key: "name",
    width: "85%"
  },
  {
    title: "",
    key: "actions",
    dataIndex: "actions",
    width: "10%"
  },
];

function Types() {

  const [data, setData] = useState([]);
  const [selectedData, setSelectedData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isOpenUpdate, setIsOpenUpdate] = useState(false);
  const [isOpenCreate, setIsOpenCreate] = useState(false);

  const [form] = Form.useForm();

  useEffect(() => {
    handleGetTypes();
  }, []);

  function handleGetTypes() {
    setIsLoading(true);
    axios.get(endpoints.types.getTypes
    ).then((response) => {
      let typesData = [];
      for (let i = 0; i < response.data.length; i++) {
        let arrayData = response.data[i];
        let aux = {
          key: i + 1,
          name: arrayData.name,
          actions:
            <div className="actions-buttons-div">
              <Button type="primary" className="edit-action" onClick={() => handleOpenUpdate(arrayData)}>
                <EditOutlined style={{ fontSize: '16px' }} />
              </Button>
            </div>
        }

        typesData.push(aux);
      }

      setData(typesData);
      setIsLoading(false);
    }).catch((err) => {
      console.log(err);
    });
  }

  function handleOpenCreate() {
    setIsOpenCreate(true);
  }

  function handleCloseCreate() {
    setIsOpenCreate(false);
    handleGetTypes()
  }

  function handleOpenUpdate(item) {
    setSelectedData(item);
    setIsOpenUpdate(true);
  }

  function handleCloseUpdate() {
    setIsOpenUpdate(false);
    setSelectedData({});
  }

  return (
    <div className="tabled">
      <Create open={isOpenCreate} close={handleCloseCreate} />
      <Update data={selectedData} open={isOpenUpdate} close={handleCloseUpdate} />
      <Row gutter={[24, 0]}>
        <Col xs="24" xl={24}>
          <Card
            bordered={false}
            className="criclebox tablespace mb-24"
            title="Types Table"
            extra={[
              <Button size="large" className="mr-10" onClick={handleGetTypes}><ReloadOutlined /></Button>,
              <Button size="large" type="primary" onClick={handleOpenCreate}>Create Type</Button>
            ]}
          >
            <div className="table-responsive">
              <Table
                loading={isLoading}
                columns={columns}
                dataSource={data}
                className="ant-border-space"
              />
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default Types;
