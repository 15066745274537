import {
  Button,
  Input,
  Form,
  notification,
  Upload,
  Select,
  Modal
} from "antd";
import {
  InboxOutlined,
} from '@ant-design/icons';
import { useEffect, useState } from "react";
import dayjs from 'dayjs';
import axios from 'axios';

import config from "../../utils/config";
import endpoints from "../../utils/endpoints";

const { Dragger } = Upload;
const { Option } = Select;

export default function Update({ data, open, close }) {

  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const [form] = Form.useForm();

  useEffect(() => {
    if (Object.keys(data).length > 0 && open) {
      form.setFieldsValue(data);
    }
  }, [open])

  function handleSubmit(values) {
    setIsButtonLoading(true);
    axios.post(endpoints.types.editType, {
      data: values
    }).then((res) => {
      setTimeout(() => {
        handleClose();
      }, 1000);
    }).catch((err) => {
      setIsButtonLoading(false);
      console.log(err);
    });
  }

  function handleWarningMissingFields() {
    notification.error({
      message: `Campos por preencher`,
      description: 'Faltam alguns campos para preencher!'
    });
  }

  function handleClose() {
    close();
    setIsButtonLoading(false);
    form.resetFields();
  }

  return (
    <Modal
      title={`Update type`}
      id="update-type"
      open={open}
      onCancel={handleClose}
      footer={[
        <Button size="large" onClick={handleClose} disabled={isButtonLoading}>
          Cancel
        </Button>,
        <Button size="large" type="primary" onClick={form.submit} loading={isButtonLoading}>
          Update
        </Button>
      ]}
    >
      <Form
        form={form}
        id="update-type-form"
        layout="vertical"
        onFinish={handleSubmit}
        onFinishFailed={handleWarningMissingFields}
      >
        <Form.Item
          name="name"
          label="Name"
          rules={[{ required: true, message: 'This field is required.' }]}
        >
          <Input type="text" placeholder="Name..." />
        </Form.Item>
      </Form>
    </Modal>
  );
}