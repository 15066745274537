import {
  Button,
  Input,
  Form,
  notification,
  Upload,
  Select,
  Modal,
  Row,
  Col
} from "antd";
import axios from 'axios';
import { useState } from "react";
import dayjs from 'dayjs';

import config from "../../utils/config";
import localeData from 'dayjs/plugin/localeData';
import endpoints from "../../utils/endpoints";

const { Dragger } = Upload;
const { Option } = Select;
dayjs.extend(localeData)

export default function Create({ open, close }) {

  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const [form] = Form.useForm();

  function handleSubmit(values) {
    setIsButtonLoading(true);
    axios.post(endpoints.events.postEvent, {
      data: values
    }).then((res) => {
      setTimeout(() => {
        handleClose();
      }, 1000);
    }).catch((err) => {
      setIsButtonLoading(false);
      console.log(err);
    });
  }

  function handleWarningMissingFields() {
    notification.error({
      message: `Campos por preencher`,
      description: 'Faltam alguns campos para preencher!'
    });
  }

  function handleClose() {
    close();
    form.resetFields();
    setIsButtonLoading(false);
  }

  return (
    <Modal
      title={`Create event`}
      id="create-event"
      open={open}
      onCancel={handleClose}
      footer={[
        <Button size="large" onClick={handleClose} disabled={isButtonLoading}>
          Cancel
        </Button>,
        <Button size="large" type="primary" onClick={form.submit} loading={isButtonLoading}>
          Create
        </Button>
      ]}
    >
      <Form
        form={form}
        id="create-event-form"
        layout="vertical"
        onFinish={handleSubmit}
        onFinishFailed={handleWarningMissingFields}
      >
        <Form.Item
          name="name"
          label="Name"
          rules={[{ required: true, message: 'This field is required.' }]}
        >
          <Input type="text" placeholder="Name..." />
        </Form.Item>
      </Form>
    </Modal>
  );
}