import { useState, useEffect } from "react";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { Layout, Drawer, Affix, Row, Col, Icon } from "antd";
import axios from 'axios'
import Sidenav from "./Sidenav";
import endpoints from "../../utils/endpoints";
import { ProfileOutlined, UserOutlined } from "@ant-design/icons";
import Loading from "../Loading/Loading";

const { Header, Content, Sider, Footer } = Layout;

export default function Main({ handleLogout }) {
  const [visible, setVisible] = useState(false);
  const [sidenavColor, setSidenavColor] = useState("#00C16E");
  const [sidenavType, setSidenavType] = useState("transparent");
  const [fixed, setFixed] = useState(true);
  const [user, setUser] = useState({})
  const [isLoading, setIsLoading] = useState(true)

  const navigate = useNavigate();

  let { pathname } = useLocation();
  pathname = pathname.replace("/", "");

  return (
    <Layout
      className={`layout-dashboard ${pathname === "profile" ? "layout-profile" : ""
        }`}
    >
      <Drawer
        title={false}
        closable={false}
        onClose={() => setVisible(false)}
        visible={visible}
        width={250}
        className={`drawer-sidebar`}
      >
        <Layout
          className={`layout-dashboard`}
        >
          <Sider
            trigger={null}
            width={250}
            theme="light"
            className={`sider-primary ant-layout-sider-primary ${sidenavType === "#fff" ? "active-route" : ""
              }`}
            style={{ background: sidenavType }}
          >
            <Sidenav color={sidenavColor} />
          </Sider>
        </Layout>
      </Drawer>
      <Sider
        breakpoint="lg"
        collapsedWidth="0"
        onCollapse={(collapsed, type) => {
          console.log(collapsed, type);
        }}
        trigger={null}
        width={250}
        theme="light"
        className={`sider-primary ant-layout-sider-primary ${sidenavType === "#fff" ? "active-route" : ""
          }`}
        style={{ background: sidenavType }}
      >
        <Sidenav color={sidenavColor} />
      </Sider>
      <Layout>
        {fixed ? (
          <Affix>
            <Header className={`${fixed ? "ant-header-fixed" : ""}`}>
              <Row gutter={[24, 0]}>
                <Col span={24} md={6}>
                </Col>
                <Col span={24} md={18} className="header-control">
                  <Link onClick={handleLogout} className="d-flex jc-center ai-center">
                    <UserOutlined />
                    <p className="ml-10 m-0">Logout</p>
                  </Link>
                </Col>
              </Row>
            </Header>
          </Affix>
        ) : (
          <Header className={`${fixed ? "ant-header-fixed" : ""}`}>
            <Row gutter={[24, 0]}>
              <Col span={24} md={6}>
              </Col>
              <Col span={24} md={18} className="header-control">
                <Link onClick={handleLogout} className="d-flex jc-center ai-center">
                  <UserOutlined />
                    <p className="ml-10 m-0">Logout</p>
                </Link>
              </Col>
            </Row>
          </Header>
        )}
        <Content className="content-ant">
          <Outlet />
        </Content>
        <Footer style={{ background: "#fafafa" }}>
          <Row className="just">
            <Col xs={24} md={12} lg={12}>
              <div className="copyright">
                © 2022, made by
                <a href="#pablo" className="font-weight-bold" target="_blank">
                  Phormula Group
                </a>
              </div>
            </Col>
          </Row>
        </Footer>
      </Layout>
    </Layout>
  );
}