import {
  Row,
  Col,
  Card,
  Table,
  Button,
} from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  ReloadOutlined,
} from '@ant-design/icons';
import { useEffect, useState } from "react";
import axios from 'axios';

import config from "../../utils/config";
import Create from "../../components/Podcasts/Create";
import Update from "../../components/Podcasts/Update";
import endpoints from "../../utils/endpoints";
import "./Podcasts.css";
import Delete from "../../components/Podcasts/Delete";


function Podcasts() {

  const [podcasts, setPodcasts] = useState([]);
  const [selectedData, setSelectedData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isOpenCreate, setIsOpenCreate] = useState(false);
  const [isOpenUpdate, setIsOpenUpdate] = useState(false);
  const [isOpenDelete, setIsOpenDelete] = useState(false);

  const columns = [
    {
      title: "IMAGE",
      dataIndex: "image",
      key: "image",
      width: "10%",
    },
    {
      title: "NAME",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "DESCRIÇÃO",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "LINK",
      dataIndex: "link",
      key: "link",
    },
    {
      title: "",
      key: "actions",
      dataIndex: "actions",
      width: "10%",
    },
  ];

  useEffect(() => {
    handleGetPodcasts();
  }, []);

  function handleGetPodcasts() {
    setIsLoading(true);
    axios.get(endpoints.podcasts.getPodcasts).then((response) => {
      let podcastsData = [];
      for (let i = 0; i < response.data.length; i++) {
        let arrayData = response.data[i];
        let aux = {
          key: i,
          image: <div className="img-podcast"><img src={`${config.server_ip}/images/podcasts/${arrayData.img}`} /></div>,
          name: arrayData.name,
          description: arrayData.description,
          description: arrayData.description,
          link: arrayData.link,
          actions: (
            <div className="actions-buttons-div">
              <Button type="primary" className="edit-action" onClick={() => handleOpenUpdate(arrayData)}>
                <EditOutlined style={{ fontSize: '16px' }} />
              </Button>
              <Button danger className="delete-action" onClick={() => handleOpenDelete(arrayData)}>
                <DeleteOutlined style={{ fontSize: '16px' }} />
              </Button>
            </div>
          )
        }

        podcastsData.push(aux);
      }

      setPodcasts(podcastsData);
      setIsLoading(false);
    }).catch((err) => {
      console.log(err);
    });
  }

  function handleOpenCreate() {
    setIsOpenCreate(true);
  }

  function handleCloseCreate() {
    setIsOpenCreate(false);
    handleGetPodcasts();
  }

  function handleOpenUpdate(item) {
    setSelectedData(item);
    setIsOpenUpdate(true);
  }

  function handleCloseUpdate() {
    setIsOpenUpdate(false);
    setSelectedData({});
    handleGetPodcasts();
  }

  function handleOpenDelete(item) {
    setSelectedData(item);
    setIsOpenDelete(true);
  }

  function handleCloseDelete() {
    setIsOpenDelete(false);
    setSelectedData({});
    handleGetPodcasts();
  }

  return (
    <div className="tabled">
      <Create open={isOpenCreate} close={handleCloseCreate} />
      <Update data={selectedData} open={isOpenUpdate} close={handleCloseUpdate} />
      <Delete data={selectedData} open={isOpenDelete} close={handleCloseDelete} />
      <Row gutter={[24, 0]}>
        <Col xs="24" xl={24}>
          <Card
            bordered={false}
            className="criclebox tablespace mb-24"
            title="Podcasts Table"
            extra={[
              <Button size="large" className="mr-10" onClick={handleGetPodcasts}><ReloadOutlined /></Button>,
              <Button size="large" type="primary" onClick={handleOpenCreate}>Create podcast</Button>
            ]}
          >
            <div className="table-responsive">
              <Table
                columns={columns}
                dataSource={podcasts}
                loading={isLoading}
                className="ant-border-space"
              />
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default Podcasts;
