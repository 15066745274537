import React, { useEffect, useState } from "react";
import {
  Layout,
  Button,
  Row,
  Col,
  Typography,
  Form,
  Input,
  Switch,
  notification
} from "antd";
import axios from "axios";
import "./Login.css";
import config from "../../utils/config";
import endpoints from "../../utils/endpoints";
import { useNavigate } from "react-router-dom";
import Loading from "../../layout/Loading/Loading";
import api from "../../utils/api";
import logoDark from '../../assets/images/logotipo-MP-dark.svg';

const { Title } = Typography;
const { Content } = Layout;

export default function Login({ handleLogin }) {

  const [isButtonLoading, setIsButtonLoading] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  function onChange(checked) {
    console.log(`switch to ${checked}`);
  }

  function onFinish(values) {
    setIsButtonLoading(true)
    axios.post(endpoints.auth.login, {
      data: values
    }).then(async (res) => {
      if (res.data.user_exists && res.data.user_password) {
        let userData = res.data.data[0]
        if (userData.type === 'admin') {
          localStorage.setItem('token', res.data.token);
          await api.axiosInit(res.data.token)
          handleLogin();
          notification.success({
            message: `Login efetuado`,
            description: 'Login efetuado com sucesso!'
          });
        } else {
          notification.error({
            message: `Sem permissões`,
            description: 'Não tem permissões para entrar no backoffice'
          });
        }
      } else {
        if (!res.data.user_exists) {
          notification.error({
            message: `E-mail errado`,
            description: 'Este e-mail não existe na base dados, por favor verifique se o e-mail está correto!'
          });
        } else if (!res.data.user_password) {
          notification.error({
            message: `Password errada`,
            description: 'Por favor verifique a sua password!'
          });
        }
      }

      setIsButtonLoading(false)
    }).catch((err) => {
      console.log(err);
      notification.error({
        message: `Erro`,
        description: 'Alguma coisa de errado aconteceu, tente novamente mais tarde!'
      });
    })
  };

  function onFinishFailed(errorInfo) {
    console.log("Failed:", errorInfo);
    notification.error({
      message: `Alguma coisa está em falta!`,
      description: 'Por favor verifique se preencheu todos os campos necessários!'
    });
  };

  return (
    <div className="w-100 h-100 d-flex flex-column jc-center ai-center" style={{ minHeight: '100vh', background: '#009153' }}>
      <Row className="w-60 tablet-w-85 mobile-w-95 d-flex jc-center ai-center">
        <Col
          xs={{ span: 22 }}
          lg={{ span: 12 }}
          md={{ span: 12 }}
          className="d-flex flex-column jc-center ai-center w-100 p-30 bg-white column-img"
          style={{ minHeight: 500, borderTopLeftRadius: 10, borderBottomLeftRadius: 10 }}
        >
          <div className="w-100 d-flex jc-center ai-center flex-column h-100">
            <img style={{ maxWidth: 250 }} src="https://medicalportfolioapp.com/wp-content/uploads/2022/08/medical-app-mockup@2x.png" />
          </div>
        </Col>
        <Col
          xs={{ span: 0 }}
          lg={{ span: 12 }}
          md={{ span: 12 }}
          className="d-flex flex-column jc-center ai-center w-100 p-50 bg-white"
          style={{ minHeight: 500, borderTopRightRadius: 10, borderBottomRightRadius: 10 }}
        >
          <div className="w-100 d-flex jc-center ai-center flex-column">
            <p className="mb-10 mt-0 f-40 bold">Login</p>
            <p className="f-15 mt-0 mb-10">Insira o seu e-mail e password para fazer login</p>
            <Form
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              layout="vertical"
              className="w-100"
            >
              <Form.Item
                className="w-100"
                label="E-mail"
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Por favor insira o seu e-mail!",
                  },
                ]}
              >
                <Input className="w-100" placeholder="E-mail" />
              </Form.Item>

              <Form.Item
                className="password"
                label="Password"
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Por favor insira a sua password!",
                  },
                ]}
              >
                <Input type="password" placeholder="Password" />
              </Form.Item>

              <td className="d-flex jc-start ai-center mb-20">
                <Form.Item
                  name="remember"
                  valuePropName="checked"
                  className="m-0"
                >
                  <Switch defaultChecked onChange={onChange} />
                </Form.Item>
                <span className="ml-10">Lembrar-me</span>
              </td>

              <Form.Item className="mb-0">
                <Button
                  size="large"
                  type="primary"
                  htmlType="submit"
                  style={{ width: "100%" }}
                >
                  Entrar
                </Button>
              </Form.Item>
            </Form>
          </div>
        </Col>
      </Row>
    </div>
  );
}
