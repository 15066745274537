import { Menu, Button } from "antd";
import { NavLink, useLocation } from "react-router-dom";
import logo from "../../assets/images/logotipo-MP-dark.svg";
import {
  ProfileOutlined,
  FileOutlined
} from '@ant-design/icons'

function Sidenav({ color }) {
  const { pathname } = useLocation();
  const page = pathname.replace("/", "");

  return (
    <>
      <div className="brand">
        <img src={logo} alt="" />
        <span>Medical Portfolio</span>
      </div>
      <hr />
      <Menu theme="light" mode="inline">
        <Menu.Item key="1">
          <NavLink to="/dashboard">
            <span
              className="icon"
              style={{
                background: page === "dashboard" ? color : "",
              }}
            >
              <ProfileOutlined />
            </span>
            <span className="label">Dashboard</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="2">
          <NavLink to="/papers">
            <span
              className="icon"
              style={{
                background: page === "papers" ? color : "",
              }}
            >
              <FileOutlined />
            </span>
            <span className="label">Papers</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="3">
          <NavLink to="/categories">
            <span
              className="icon"
              style={{
                background: page === "categories" ? color : "",
              }}
            >
              <FileOutlined />
            </span>
            <span className="label">Categories</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="4">
          <NavLink to="/types">
            <span
              className="icon"
              style={{
                background: page === "types" ? color : "",
              }}
            >
              <FileOutlined />
            </span>
            <span className="label">Types</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="5">
          <NavLink to="/events">
            <span
              className="icon"
              style={{
                background: page === "events" ? color : "",
              }}
            >
              <FileOutlined />
            </span>
            <span className="label">Events</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="6">
          <NavLink to="/podcasts">
            <span
              className="icon"
              style={{
                background: page === "podcasts" ? color : "",
              }}
            >
              <FileOutlined />
            </span>
            <span className="label">Podcasts</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="7">
          <NavLink to="/users">
            <span
              className="icon"
              style={{
                background: page === "users" ? color : "",
              }}
            >
              <FileOutlined />
            </span>
            <span className="label">Users</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="8">
          <NavLink to="/versions">
            <span
              className="icon"
              style={{
                background: page === "versions" ? color : "",
              }}
            >
              <FileOutlined />
            </span>
            <span className="label">Versions</span>
          </NavLink>
        </Menu.Item>
      </Menu>
    </>
  );
}

export default Sidenav;
