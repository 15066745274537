import {
  Row,
  Col,
  Card,
  Table,
  Button,
  Select
} from "antd";
import {
  EditOutlined,
  LoadingOutlined,
  ReloadOutlined,
} from '@ant-design/icons';
import { useEffect, useState } from "react";
import axios from 'axios';

import config from "../../utils/config";
import endpoints from "../../utils/endpoints";
import Create from "../../components/Categories/Create";
import Update from "../../components/Categories/Update";
import "./Categories.css";

export default function Categories() {

  const [data, setData] = useState([]);
  const [selectedData, setSelectedData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenUpdate, setIsOpenUpdate] = useState(false);
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [isOpenCreate, setIsOpenCreate] = useState(false);

  const columns = [
    {
      title: "IMAGE",
      dataIndex: "image",
      key: "image",
      width: "5%",
    },
    {
      title: "NAME",
      dataIndex: "name",
      key: "name",
      width: "50%",
    },
    {
      title: "DAYS",
      dataIndex: "days",
      key: "days",
      width: "20%",
    },
    {
      title: "POSITION",
      dataIndex: "position",
      key: "position",
      width: "10%",
    },
    {
      title: "",
      key: "actions",
      dataIndex: "actions",
      width: "10%",
    },
  ];

  useEffect(() => {
    handleGetCategories();
  }, []);

  function handleGetCategories() {
    setIsLoading(true);
    axios.get(endpoints.categories.getCategoriesBackoffice).then((response) => {
      let categoriesData = [];
      for (let i = 0; i < response.data.length; i++) {
        let arrayData = response.data[i];
        let daysString = getDaysString(response.data[i]);
        let aux = {
          key: i,
          image: <div className="img-category"><img src={`${config.server_ip}/images/categories/${arrayData.img}`} /></div>,
          name: arrayData.name,
          days: daysString,
          position: arrayData.position,
          actions:
            <div className="actions-buttons-div">
              <Button type="primary" className="edit-action" onClick={() => handleOpenUpdate(arrayData)}>
                <EditOutlined style={{ fontSize: '16px' }} />
              </Button>
            </div>
        }

        categoriesData.push(aux);
      }

      setData(categoriesData);
      setIsLoading(false);
    }).catch((err) => {
      setIsLoading(false);
      console.log(err);
    });
  }

  function getDaysString(data) {
    let days = JSON.parse(data.days)
    let string = '';
    if (days.length > 0) {
      for (let i = 0; i < days.length; i++) {
        switch (days[i]) {
          case 0:
            string += 'Domingo';
            break;
          case 1:
            string += '2ª';
            break;
          case 2:
            string += '3ª';
            break;
          case 3:
            string += '4ª';
            break;
          case 4:
            string += '5ª';
            break;
          case 5:
            string += '6ª';
            break;
          case 6:
            string += 'Sábado';
            break;
          default:
            break;
        }

        if (i < (days.length - 1)) {
          string += ", "
        }
      }

      return string;
    } else {
      return 'Nenhum'
    }
  }

  function handleOpenCreate() {
    setIsOpenCreate(true);
  }

  function handleCloseCreate() {
    setIsOpenCreate(false);
    handleGetCategories();
  }

  function handleOpenUpdate(item) {
    item.days = JSON.parse(item.days);
    setSelectedData(item);
    setIsOpenUpdate(true);
  }

  function handleCloseUpdate() {
    setIsOpenUpdate(false);
    setSelectedData({});
  }

  function handleOpenDelete(data) {
    setSelectedData(data);
    setIsOpenDelete(true);
  }

  function handleCloseDelete() {
    setIsOpenDelete(false);
    setSelectedData({});
  }

  return (
    <div className="tabled">
      <Create open={isOpenCreate} close={handleCloseCreate} />
      <Update data={selectedData} open={isOpenUpdate} close={handleCloseUpdate} />
      <Row gutter={[24, 0]}>
        <Col xs="24" xl={24}>
          <Card
            bordered={false}
            className="criclebox tablespace mb-24"
            title="Categories Table"
            extra={[
              <Button size="large" className="mr-10" onClick={handleGetCategories}><ReloadOutlined /></Button>,
              <Button size="large" type="primary" onClick={handleOpenCreate}>Create Category</Button>
            ]}
          >
            <div className="table-responsive">
              <Table
                loading={isLoading}
                columns={columns}
                dataSource={data}
                className="ant-border-space"
              />
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
}
